.hero{
    width: 100%;
    height: auto;
    position: relative;
    

}
.heroImg{
    width: 100%;
    height: 100%;
    object-fit: fill;
    display: block;
    
}