.NavbarItems{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 20px 80px;
    background-color: #045FD1;
    
}
.Logo{
    width: calc(100% /2);
    height: calc(100% /2);
    
}
.nav-menu{
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
}

.nav-links{
    text-decoration: none;
    color: #fff;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}
.fa-bars,
.fa-times{
    color: #fff;
}
.icon-menu{
    display: none;
}


@media screen and (max-width:850px){
    .NavbarItems{
        z-index: 999;
        padding: 20px 25px;
    }
    .icon-menu{
        display: block;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: start;
        background:rgba( 255, 255, 255, .25);
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, .37);
        backdrop-filter: blur(4px);
        border-radius: 5px;
        width: 100%;
        height: auto;
        position: absolute;
        top:11%;
        left: -110%;
        align-items: stretch;
        padding: 20px 0 30px 0 ;
        margin: 0;
        z-index: 99;
        
        
    }
    .nav-menu.active{
        left: 0;
    }
    .nav-links{
        display: block;
        widows:100%;
        padding:2rem 0;
        color: #222;
    }
    .nav-links:hover{
        background: rgba( 255, 255, 255, .25);
        backdrop-filter: blur(20px);

    }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
    .Logo{
        
    }
}