.ColorChart{
    width: 100%;
    height: auto;
    background: #f5f5f5;
    
}
.ColorChart-Container{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.ColorChart-Title{
    width: 100%;
    background: #5A9BED;
    
}
.ColorChart-Title h1{
    font-size: 58px;
    padding: 16px 0;
}
.Color-item{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 28px;
    margin: 32px 0;
}
.Color-card{
    width: 190px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.Color-code,
.Color-name{
    color: #000;
    font-weight: 500;
}
.Color-card img{
    width: 100px;
    height: 100px;
    /* object-fit: cover; */
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
    .ColorChart-Title h1{
        font-size: calc(58px /2);
    }
    .Color-card{
        width: calc(190px /1.7);
    }
    .Color-card img{
        width: 80px;
        height: 80px;
    }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .ColorChart-Title h1{
        font-size: calc(58px /2);
    }
    .Color-card{
        width: calc(190px /1.7);
    }
    .Color-card img{
        width: 80px;
        height: 80px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .ColorChart-Title h1{
        font-size: calc(58px /2);
    }
    
    
}