.Product-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    background: linear-gradient(to top, #045fd1 0%,#045fd1 88%,#5A9BED 88%,#5A9BED 100%  );
}
.Product-header{
    width: 100%;
    height:auto;
   
}
.launching{
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
}
.Product-header section{
    font-size: 83px;
    font-style: normal;
    font-weight: 700;
    line-height: 88px;
    letter-spacing: -1.5px;
    text-align: center;
    padding-top: 38px   ;
}
.Product-details{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 38px;
    gap: 38px;
}
.Navigator-container{
    width: max-content;
    height: calc(100% /2);
    background-color: #fff;
    position: relative;
    border-radius: 52px;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.40);
    align-content: center;
    white-space: nowrap;
    overflow: auto;
    
}
 
    

.Navigator-container a{
    display: inline-block;
    text-decoration: none;
    text-align: center;
    padding: 14px;
    font-weight: 700;
    font-size: calc(1vw + 1vh + 1vmin);
    
    
}

/* .Navigator-container li  {
    flex: 1;
    list-style: none;
    text-align: center;
    position: relative;
    font-size: 20px;
    font-weight: bold;
    transition: 0.5s ease;
    cursor: pointer;
    user-select: none;
    color:#045FD1 ;
    margin: 15px 0;
    
}
.Navigator-container li a{
    text-decoration: none;
    padding: 10px 16px;
    
} */
li a:hover{
    border-bottom: 5px solid #045FD1;
}
.link-active{
    border-bottom: 5px solid #045FD1;
}
Link{
    color:#045FD1 ; 
}

li:active {
    background-color: rgba(255, 255, 255, 0.25);
    color: #045FD1;
 
    
    
}
/* li:active:nth-of-type(2) ~ .bar {
    left: 31%;
}
li:active:nth-of-type(3) ~ .bar {
    left: 55%;
}
li:active:nth-of-type(4) ~ .bar {
    left: 79%;
}

 .bar {
    width: 15%;
    background-color: #045FD1;
    height: 5px;
    position: absolute;
    left: 5%;
    bottom: 0;
    transition: 0.5s ease;
} */

/* .Product-img{
    width: 80%;
    height: 540px;
    align-items: center;
    background: #e4e3e3;
    border-radius: 64px;
   
  
    
} */
.Product-desc{
    padding-top: 40px;
    padding-bottom: 40px;
}
.Product-desc h2,
.Product-desc h4,
.Product-desc p{
    text-align: left;
}
.Product-img img{
    width: 100%;
    border-radius: 64px;
}
.HowToUse{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 38px;
}
.HowToUse-img-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 32px;
}
.HowToUse-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    flex: 1 1 25.5555%;
}
.HowToUse-img img{
    width: 100%;
    border-radius: 3%;
}
.HowToApply{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 30px;
}
.HowToApply h3{
    align-self: self-start;
}
.HowToApply ul,
.HowToApply p{
    text-align: left;
}
.HowToApply ul{
    list-style: decimal;
    letter-spacing: 0.5px;
}
.VideoHowtoApply{
    /* width: 980px;
    height: 505px; */
    align-self: center;
}
.VideoHowtoApply iframe{
    width: 100%;
    height: 100%;
}


.Text-Transition{
    display: inline;
}
.header-titile-product{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}
.header-titile-product >section > .animation{
    display: inline-block;
    
}


@media only screen and (min-width: 821px) and (max-width: 1024px) {
    
    .Product-header section{
        width: 100%;
        font-size: calc(80px /2);
        line-height: calc(88px /2);
        padding-top: 0;
    }
    .Navigator-container ul{
        padding: 0;
        margin: 0;
    }
    .Navigator-container li{
        font-size: 17px;
        margin: 5px;
        
    }
    .Navigator-container a{
        display: inline-block;
        text-decoration: none;
        text-align: center;
        padding: 14px;
        font-weight: 700;
    }
    .Product-img img{
        border-radius: calc(64px /2);
    }
    /* .VideoHowtoApply{
        width: 100%;
        height: 100%;
    } */
    .HowToApply li{
        margin-bottom: 10px;
        display: list-item;
    }
    .HowToApply p {
        font-size: 15.5px;
    }
}
@media only screen and (min-width: 481px) and (max-width: 820px) {
    
    .Product-header section{
        width: 100%;
        font-size: calc(80px /2);
        line-height: calc(88px /2);
        padding-top: 0;
    }
    .Navigator-container {
        padding: 0;
        margin: 0;
        width: calc(100% /2);
        overflow: auto;
    }
    .Navigator-container li{
        font-size: 17px;
        margin: 5px;
    }
    .Navigator-container a{
        
        display: inline-block;
        text-decoration: none;
        text-align: center;
        padding: 14px;
        font-weight: 700;
        font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    }
    .Product-img img{
        border-radius: calc(64px /1.5);
    }
    /* .VideoHowtoApply{
        width: 100%;
        height: 100%;
    } */
    .HowToApply li{
        margin-bottom: 10px;
        display: list-item;
    }
    .HowToApply p {
        font-size: 15.5px;
    }
    .HowToUse{
        flex-direction: column;
        gap: 10px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
    .Product-header section{
        width: 100%;
        font-size: calc(80px /2);
        line-height: calc(88px /2);
        padding-top: 0;
    }
    .Navigator-container {
        width: calc(100% /1.2);
        padding: 0;
        margin: 0;
        overflow-x: auto;
        white-space: nowrap;
        
        
    }
    .Navigator-container li{
        font-size: 17px;
        margin: 5px;
        
    }
    .Navigator-container a{
        display: inline-block;
        text-decoration: none;
        text-align: center;
        padding: 14px;
        font-weight: 700;
        font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    }
    .Product-img img{
        border-radius: calc(64px /2);
    }
    /* .VideoHowtoApply{
        width: 100%;
        height: 100%;
    } */
    .HowToApply li{
        margin-bottom: 10px;
        display: list-item;
    }
    .HowToApply p {
        font-size: 15.5px;
    }
    .HowToUse-img-container{
        flex-direction: column;

    }
    .Product-desc{
        padding: 0;
    }
    
}
.animation {
    
    height:65px;
    overflow:hidden;
    margin-left: 1rem;
    
  }
  
  .animation > div > div {
    padding: 0.25rem 0.75rem;
    height:3.81rem;
    margin-bottom: 2.81rem;
    display:inline-block;
    
  }
  
  .animation div:first-child {
    animation: text-animation 10s infinite;
  }
  @keyframes text-animation {
    0%{
        margin-top:0;
      }
      25%{
        margin-top: -2rem;    
      }
      50%{
        margin-top: -5rem;
      }
      72.5%{
        margin-top: -9.45rem;
      }
      80%{
        margin-top: -14.25rem;
      }
      100%{
        margin-top: -16.25rem;
      }
  }
  @media only screen and (min-width: 320px) and (max-width: 980px) {
    @keyframes text-animation {
        0%{
            margin-top:0;
          }
          25%{
            margin-top: -1rem;    
          }
          50%{
            margin-top: -3rem;
          }
          72.5%{
            margin-top: -7.45rem;
          }
          80%{
            margin-top: -11.25rem;
          }
          100%{
            margin-top: -12.25rem;
          }
      }
  }
