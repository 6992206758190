.DIY-Container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.DIY-Banner{
    width: 100%;
    height: auto;
    position: relative;
}
.DIY-img{
    width: 100%;
   height: 526px;
    object-fit: cover ;
    display: block;
}
.DIY-Content{
    width: 100%;
    height: auto;
    background: #045FD1;
    padding-bottom: 20px;
}
.DIY-Card{
    width: 400px;
    height: 400px;
    border-radius: 5%;
    background: #fff;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(black,0.5);
}
.DIY-Card:hover{
    transform: translateY(20px);
    
}
.DIY-Card:hover::before{
    opacity: 1;
}
.DIY-Card:hover .DIY-Caption{
    opacity: 1;
    transform: translateY(0px);
}
.DIY-Card::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
}
.DIY-Card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5%;
}
.DIY-Card  .DIY-Caption{
    position: relative;
    z-index: 3;
    color: white;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s;
}
.DIY-Card .info h1 {
    margin: 0px;
 }
.DIY-Card .DIY-Caption p {
    letter-spacing: 1px;
    font-size: 15px;
    margin-top: 8px; }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
       h1,h2,h3{
        padding: 0.5rem 0;
       }
       .container{
        padding: 1.5rem;
       }
        .DIY-Card{
            width: 145px;
            height: 145px;
        }
    }
    @media only screen and (min-width: 481px) and (max-width: 820px) {
        h1,h2,h3{
         padding: 0.5rem 0;
        }
        .container{
         padding: 1.5rem;
        }
         .DIY-Card{
             width: 200px;
             height: 200px;
         }
     }
     @media only screen and (min-width: 821px) and (max-width: 991px) {
        h1,h2,h3{
         padding: 0.5rem 0;
        }
        .container{
         padding: 1.5rem;
        }
         .DIY-Card{
             width: 300px;
             height: 300px;
         }
     }