.home{
    width: 100%;
    height: auto;
    background: #5A9BED;
    justify-content: center;
}
.hi-temp{
    width: 100%;
    height: auto;
    background: #045FD1;
    justify-content: center;
}
hr{
    margin-top: 2%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height:4px;border-width:0;color:#FFFFFF66;background-color:#FFFFFF66
    
}
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(8px + 1.5625vw);
    
}
.container_produk{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.first{
    
    display: flex;
    /* width:80%; */
    align-items: start;
    justify-content: space-around;
    /* padding-top: 4.5rem; */
}
.desc_text{
    /* width: 60%; */
    text-align: start;
    font-size: 1.1rem;
    
}
.first2{
    
    display: flex;
    flex-direction: row-reverse;
    /* width: 80%; */
    align-items: start;
    justify-content: space-around;
    /* padding-top: 4.5rem; */
}
.desc_text h1,
.desc_text h4,
.desc_text p
{
    padding-bottom: 1rem;
}
.desc_text p{
    line-height: 24px;
}
.desc_text h4{
    font-weight: 400;
}
.homeImg{
    width: 100%;
    position: relative;
    height: auto;
    display: flex;
    justify-content: space-around;
   

}
.homeImg img{
   /* width: 272px;
   height: 472px; */
    /* height: auto; */
    /* object-fit: fill; */
    /* border-radius: 6px; */
    box-shadow: -1px;
    width: 100%;
}
.btn_produk{
    width: fit-content;
    border: solid 2px #fff;
    background: #5A9BED;
    padding: 15px 30px;
    margin: 30px 0;
    font-weight: 700;
}
.btn_produk:hover{
    background:#045FD1 ;
}
.btn_produk2{
    width: fit-content;
    border: solid 2px #fff;
    background:  #045FD1;
    padding: 15px 30px;
    margin: 30px 0;
    font-weight: 700;
}
.btn_produk2:hover{
    background:#5A9BED ;
}
.usp{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    justify-content: center;
    margin: auto;
    
}
.USPCard{
    width: 100%;
    /* border: 1px solid black; */
    display: grid;
    grid-template-rows: 100px 1fr 2fr;
    gap:8px;
}
.USPCardDesc{
    display: grid;
    place-items: start;
    font-size: 17px;
}
.USPCardImg, .USPCardTitle{
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    display: grid;
    place-items: center;
}
.usp img{
    width: 100px;
    height: 100px;
    margin-bottom:15px ;
}
.diy{
    width: 100%;
    height: auto;
    /* padding: 4.2rem; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(180deg, #428EEE 0%, rgba(255, 255, 255, 0.00) 150%);
}
.diy_container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}
.diy_img_container{
   width: 100%;
    display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   gap: 2rem;
   flex-wrap: wrap;
   
}
.diy_img_container img{
    border-radius: 5%;
    
}
.diy_img_container img:hover{
    transform: scale(1.1);
    transition: 0.5s;
}
.diy button{
    border:#5A9BED;
    border-radius: 10%;
    box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.25);
    background: #5A9BED;
    padding: 25px 50px;
    margin-top: 30px;
    font-size: 1.5rem;
    font-weight: 700;
}
.DIY-Content button{
    border:#5A9BED;
    border-radius: 10%;
    box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.25);
    background: #5A9BED;
    padding: 25px 50px;
    margin-top: 30px;
    font-size: 1.5rem;
    font-weight: 700;
}
.product_list{
    width: 100%;
    height: auto;
    /* padding: 4.2rem; */
    background: #045FD1 ;
}
.product_list_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0;
}
.product_list_img{
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 80%;
}
.product_list_img a{
    text-decoration: none;
}
.product_list_img .product_img{
    /* width: 230px;
    height: 370px; */
    object-fit: fill;
    border-radius: 5%;
    /* background: #fff; */
    
}
.product_img:hover{
    transform: scale(1.1);
    transition: 0.5s;
}

.product{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.slider{
    background-color: #045FD1;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.slider h2{
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 121.212% */
    letter-spacing: 0.25px;
}


.slick-slider {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }
  .slick-list {
    position: relative;
    display: block !important;
    width: 100%;
  }
  .slick-slide div {
    width: auto;
    margin-right: 10px;
  }
  .slick-slide img {
    width: 100% !important;
    border-radius: 5%;
  }
.Custom-modal-content{
    background-color: #c0c0c02f;
}
.Modalbody{
    background-color: #FFFFFF66;
}
.ubahsize{

    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
}
.katalog_digital{
    display: flex;
    flex-direction: column;
    align-content: center;
    background: #5A9BED;
}
.event{
    display: flex;
    flex-direction: column;
    align-content: center;
    background: #5A9BED;
}
.katalog_digital_img{
   /* width: 100%; */
   background-color: #fff;
   border: 1px #ccc;
   transition: all .3s linear;
   border-radius: 20px;
   overflow: hidden;

}
.imgkatalog{
    max-width: 100%;
    border-radius: 20px;
    object-fit: cover;
    display: block;
    
}
.imgkatalog:hover{
    transform: scale(1.1) ;
    transition: 0.3s;
}



@media only screen and (min-width: 481px) and (max-width: 820px) {
   .usp{
    grid-template-columns: 1fr 1fr;
    
   } 
   .first{
    flex-direction: column-reverse;
    align-items: center;
    padding-top:1rem ;
    }
    .first2{
    flex-direction: column-reverse;
    align-items: center;
    padding-top:1rem ;
    }
    .product_list_img{
        flex-direction: column;
        align-items: center;
    }

}
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .usp{
     grid-template-columns: 1fr 1fr;
     
    } 
    /* .first{
     flex-direction: column-reverse;
     align-items: center;
     padding-top:1rem ;
     }
     .first2{
     flex-direction: column-reverse;
     align-items: center;
     padding-top:1rem ;
     } */
     /* .product_list_img{
         flex-direction: column;
         align-items: center;
     } */
 
 }
@media only screen and (min-width: 821px) and (max-width: 1024px) {
    .usp{
     grid-template-columns: 1fr 1fr;
     
    } 
    .first{
     flex-direction: column-reverse;
     align-items: center;
     padding-top:1rem ;
     }
     .first2{
     flex-direction: column-reverse;
     align-items: center;
     padding-top:1rem ;
     }
     .product_list_img{
         flex-direction: column;
         align-items: center;
     }
 
 }
@media only screen and (min-width: 320px) and (max-width: 480px) {
    .usp{
     grid-template-columns: 1fr 1fr;
   
    } 
    .first{
        flex-direction: column-reverse;
        align-items: center;
        padding-top:1rem ;
    }
    .first2{
        flex-direction: column-reverse;
        align-items: center;
        padding-top:1rem ;
    }
    .desc_text{
        text-align: start;
        
    }
    .desc_text p{
        margin: 0;
    }
    .btn_produk{
        font-size: 14px;
        margin: 5px;
    }
    .btn_produk2{
        font-size: 14px;
        margin: 5px;
    }
    p{
        font-size: 13px;
    }
    .USPCard{
        gap: 0;
        padding: 16px 0;
    }
    .USPCardDesc{
        font-size: 14px;
    }
    .USPCardTitle{
        font-size: 17px;
    }
    .usp img{
        width: 80px;
        height: 80px;
        margin-bottom:15px ;
    }
    .diy button{
        padding: 15px 30px;
        margin-top: 20px;
        font-size: 1rem;
    }
    .diy{
        padding: 0;
    }
    .product_img{
        width: 100%;
        height: 100%;
    }
    .product_list_container h1{
        font-size: 23px;
    }.product_list_container h2{
        font-size: 20px;
    }
    .slider h2{
        font-size: 20px;
    }
    .product_list_img{
        justify-content: center;
        gap: 0;
    }
    .homeImg img{
        width: 65%;
    }
    .usp img{
        width: 60px;
        height: 60px;

    }
    
    .DIY-Content button {
        padding: 15px 25px;
        font-size: 1rem;
    }
 }
